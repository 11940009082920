// @flow

const common = require('../common/index.js');

module.exports = (client: Object) => {
    /**
     * jwt authorization kontrolü yapar
     * @param username {string} - giriş yapmak için gerekli olan isim, email vs.
     * @param password {string} - şifre
     * @param infinite {boolean} - herhangi bir sunucu hatasında sonsuza kadar denensin mi?
    */
    client.loginOnAuth = (username, password) => {
        return new Promise((resolve, reject) => {

            var callback = () => {
                client.request({
                    uri: `${client.urls.SERVICE}auth/login`,
                    method: 'POST',
                    body: common.jsonToUrlencode({
                        username: username,
                        password: password,
                    }),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                    ignoreAuth: true,
                })
                .then(res => {
                    // eğer giriş başarılı ise
                    if (res.status === 200 || res.status === 201) {
                        res = res.json();

                        // sonuç başarılı ise gelen ticket'i al
                        client.token = res.data.token;

                        resolve(client.token);
                        return;
                    }

                    resolve(false);
                })
                .catch((xhr) => {
                    if (xhr && xhr.status === 401) {
                        resolve(false);
                        return;
                    }
                    else
                        reject(xhr);
                });
            };

            callback();
        });
    };

};
