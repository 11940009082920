const common = require('../common/index');
module.exports = (client: Object) => {
    /*
     * Product listesini getirir.
     * @param userJid {string} - Mesajları getirilecek kullanıcının jid'si ör: 00001385@bnet.detaysoft.com
     * @param keyword {string} - aranacak keyword
    */

    /*
     * openfire'ın tüm isteklerini json kabul etmek için bu kullanılır
    */
    var defaultHeader = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    client.getAsset = (bundleId, releaseId) => {
        var url = `${client.urls.SERVICE}${bundleId}/asset/getList/${releaseId}`;

        return client.get(url, defaultHeader)
        .then(res => res.json());
    };

    client.addAsset = (bundleId, releaseId, file, type, os, progress) => {
        var url = `${client.urls.SERVICE}${bundleId}/asset/${releaseId}`;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);
        formData.append('os', os);

        return client.request({
            uri: url,
            body: formData,
            method: 'POST',
            strictSSL: true,
            headers: {
                'Authorization': common.getAuthKey(client),
                'Accept': 'application/json',
                'Content-Disposition': 'multipart/form-data',
            },
            defaultOrigin: true,
        }, undefined, progress)
        .then(res => res.json());
    };

    client.updateAsset = (bundleId, releaseId, assetId, type, os) => {
        var url = `${client.urls.SERVICE}${bundleId}/asset/${releaseId}/${assetId}`;
        var body = {
            type: type,
            os: os,
        };
        return client.put(url, body, defaultHeader);
    };

    client.deleteAsset = (bundleId, releaseId, assetId) => {
        var url = `${client.urls.SERVICE}${bundleId}/asset/${releaseId}/${assetId}`;

        return client.delete(url, null, defaultHeader);
    };
};
