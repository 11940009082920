import React, { Component } from 'react';
import './Dropdown.css';

import Button from '../Button/Button';

import PropTypes from 'prop-types';

const classNames = require('classnames');

export class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: undefined,
        };
    }

    onBlur(e) {
        if (this.state.show === true)
            this.setState({show: false});
    }

    render() {
        return (
            <div className={classNames('upbox-dropdown-container', this.props.className)} onBlur={this.onBlur.bind(this)}>
                {
                    <Button
                        {...this.props.buttonProps}
                        onClick={() => this.setState({ show: !this.state.show })} />
                }
                <div
                    className={classNames(
                        'upbox-dropdown',
                        this.props.animationType,
                        'upbox-dropdown-open__' + this.props.animationPosition,
                        { 'dropdown-hide': this.state.show === false },
                        { 'dropdown-show': this.state.show === true })}>
                    <ul>
                        {
                            this.props.items.map((x, i) => {
                                if (x instanceof Object) {
                                    return (
                                        <li key={i} onMouseDown={(e) => this.props.onSelect(i)}>
                                            <a href={x.link} className={x.itemClass} rel="noopener noreferrer" target='_blank'>
                                                {x.name}
                                            </a>
                                        </li>
                                    );
                                }
                                return (
                                    <li key={i} onMouseDown={(e) => this.props.onSelect(i)}>
                                        <a>
                                            {x}
                                        </a>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

Dropdown.defaultProps = {
    animationType: 'default',
    animationPosition: 'nortwest',
    items: [],
    onSelect: Function,
    buttonProps: null,
};

Dropdown.propTypes = {
    className: PropTypes.string,
    animationType: PropTypes.string,
    animationPosition: PropTypes.string,
    items: PropTypes.array,
    onSelect: PropTypes.func,
    buttonProps: PropTypes.object,
};
export default Dropdown;
