import React, { Component } from 'react';

import {
    Popup,
} from '../../index';

import '../../../asset/css/style.css';

import PropTypes from 'prop-types';

import {FaWindowClose, FaRegEye, FaRegEyeSlash} from 'react-icons/fa';

import ReleaseRequest from '../../../request/index.js';

import {toastMessage} from '../../../common/index.js';

import i18n from '../../../i18n';

import GLOBALS from '../../../globals.js';

export default class AddNewAdmin extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            hidden: true,
        };
        this.toggleShow = this.toggleShow.bind(this);
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    addAdmin() {
        const {
            username,
            name,
            mail,
            password,
        } = this.state;

        ReleaseRequest.addAdmin( mail, name, username, password).then((res) => {
            if (res.data) {
                GLOBALS.promise.view.emit('refreshAdminList');
                GLOBALS.promise.view.emit('onGroupProcess', { show: false });
                toastMessage('success', i18n.get('admin:success:added'));
            }
        })
        .catch(err => {
            if (err.status === 409)
                toastMessage('error', i18n.get('another:admin:same:name'));
            else
                toastMessage('error', i18n.get('admin:add:error'));
        });
    }

    render() {
        return (
            <Popup
                show={true}
                bigPopup={true}
                header={i18n.get('add:new:admin')}
                headerButtons={[{
                    type: 'transparent',
                    color: '#ccc',
                    icon: {
                        component: <FaWindowClose />,
                        size: 25,
                    },
                    title: i18n.get('close'),
                    onClick: this.props.onClose,
                }]}
                renderContent={() => (
                    <div >
                        <div className="add-release-form-content">
                            <div className="add-release-form-content-box">
                                <div className="form-input-row">
                                    <p className="add-release--input-title-row">{i18n.get('admin:username')}</p>
                                    <input type="text" className="input-style"  onChange={(e) => {
                                        this.setState({
                                            username: e.target.value,
                                        });
                                    }}/>
                                </div>
                                <div className="form-input-row">
                                    <p className="add-release--input-title-row">{i18n.get('admin:name')}</p>
                                    <input type="text" className="input-style"  onChange={(e) => {
                                        this.setState({
                                            name: e.target.value,
                                        });
                                    }}/>
                                </div>
                                <div className="form-input-row">
                                    <p className="add-release--input-title-row">{i18n.get('admin:mail')}</p>
                                    <input type="text" className="input-style"  onChange={(e) => {
                                        this.setState({
                                            mail: e.target.value,
                                        });
                                    }}/>
                                </div>
                                <div className="form-input-row">
                                    <p className="add-release--input-title-row">{i18n.get('admin:password')}</p>
                                    <input
                                        className="input-style"
                                        type={this.state.hidden ? 'password' : 'text'}
                                        value={this.state.password}
                                        onChange={(e) => {
                                            this.setState({
                                                password: e.target.value,
                                            });
                                        }}/>
                                    <span
                                        className="password-show"
                                        onClick={this.toggleShow}
                                        title={this.state.hidden ? i18n.get('show:password') : i18n.get('hide:password')}>
                                        {this.state.hidden ? <FaRegEye/> : <FaRegEyeSlash/>}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                footerButtons={[
                    {
                        color: '#fff',
                        backgroundColor: '#474747',
                        text:i18n.get('add'),
                        onClick:() => {this.addAdmin();},
                        className: 'footer-button',
                    }
                ]}
            />
        );
    }
}

AddNewAdmin.propTypes = {
    onClose: PropTypes.func,
};