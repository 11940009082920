import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { toast } from 'react-toastify';

import './index.css';
import './asset/css/login.css';
import './asset/css/adminProfile.css';
import './asset/css/product.css';
import './asset/css/release.css';
import './asset/css/start.css';
import './asset/css/style.css';
import './asset/css/spinners.css';

import './asset/sass/main.sass';

import {
    PopupModal,
    NotFound,
    Info,
} from './components/index';

import {
    Start,
    Login,
    AdminProfile,
    Product,
    Release,
} from './routes/index';

toast.configure();

ReactDOM.render(
    <Router>
        <Route exact component={PopupModal}/>
        <Switch>
            <Route path="/" exact component={Start}/>
            <Route path="/product" exact component={Product}/>
            <Route path="/info" exact component={Info}/>
            <Route path='/product/:bundleId' component={Release} />
            <Route path="/admin" component={Login}/>
            <Route path="/adminProfile" component={AdminProfile}/>
            <Route path="/404Page" component={NotFound}/>
            <Redirect from='*' to='/404Page' />
        </Switch>
    </Router>,
    document.getElementById('root')
);
