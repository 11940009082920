import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    Dropdown,
} from '../components/index.js';

import {
    FaTrash,
    FaEdit,
    FaPlus,

    // FaChevronRight,
    FaChevronDown,
} from 'react-icons/fa';

import GLOBALS from '../globals.js';

import ReleaseRequest from '../request/index.js';

import {
    toastMessage,
} from '../common/index.js';

import i18n from '../i18n';

export default class AdminProfile extends Component{
    constructor(props) {
        super(props);
        this.state = {
            adminData : {},
            disabledAdminsList: [],
            enabledAdminsList: [],
            adminListVisibility: false,
            activeList: 'enabledAdminsList',
        };

        this.user = localStorage.getItem('user');
        this.getAdmin = this.getAdmin.bind(this);
        this.getAdminList = this.getAdminList.bind(this);
        this.enabledAdmin = this.enabledAdmin.bind(this);
    }

    async enabledAdmin(username) {
        await ReleaseRequest.enabledAdmin(username).then((res) => {
            if (res) {
                GLOBALS.promise.view.emit('refreshAdminList');
                GLOBALS.promise.view.emit('onGroupProcess', { show: false });
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    componentDidMount() {
        if (!this.user){
            this.props.history.replace('/admin');
            toastMessage('warning', i18n.get('please:first:sign:in'));
        }
        else {
            if (this.props.match.path !== this.props.location.pathname){
                this.props.history.replace('/404Page');
            }
            else {
                this.getAdmin();
                this.getAdminList();

                GLOBALS.promise.view.on('refreshAdminList', this.getAdminList);
            }
        }

        GLOBALS.promise.view.on('refreshActiveAdmin', this.getAdmin);
    }

    componentWillUnmount() {
        GLOBALS.promise.view.off('refreshActiveAdmin', this.getAdmin);
        GLOBALS.promise.view.off('refreshAdminList', this.getAdminList);
    }

    getAdminList() {
        var user = localStorage.getItem('user');
        ReleaseRequest.getAdminList().then((res) => {
            var disabled = res.data.filter(x => x.type === 2);
            var enabled = res.data.filter(x => x.type !== 2);
            enabled = enabled.filter(x => x.username !== user);

            this.setState ({
                disabledAdminsList: disabled,
                enabledAdminsList: enabled,
            });
        })
        .catch(err => {
            console.log(err);
        });
    }

    getAdmin() {
        var user = localStorage.getItem('user');
        ReleaseRequest.getAdmin(user).then((res) => {
            this.setState({adminData: res.data});
        })
        .catch(err => {
            console.log(err);
        });
    }

    render() {
        var dropdownItems = [i18n.get('enabledAdminsList'), i18n.get('disabledAdminsList')];

        return (
            <div className="admin-profile-box">
                <div className="admin-profile-container">
                    <div className="admin-profile-container-box">
                        <div className= "admin-profile-vcard-box-container">
                            <div className = "admin-profile-vcard">
                                <div className= "admin-profile-vcard-avatar">
                                    <img
                                        alt={'Kullanıcı Resmi'}
                                        src={require('../asset/image/user.png').default}/>
                                    <div className="admin-name">{this.state.adminData.name}</div>
                                </div>
                                <div className= "admin-profile-vcard-box">
                                    <img
                                        alt={'Kullanıcı Resmi'}
                                        src={require('../asset/image/mail.png').default}/>
                                    <div className = "admin-profile-vcard-email">
                                        {this.state.adminData.mail}
                                    </div>
                                </div>
                                <div className="admin-profile-edit-button">
                                    <Button
                                        color='#ccc'
                                        backgroundColor="#404142"
                                        onClick={() => {
                                            GLOBALS.promise.view.emit('onGroupProcess', {
                                                show: true,
                                                type: 'updateAdmin',
                                                props: {
                                                    adminData: this.state.adminData,
                                                },
                                            });
                                        }}
                                        icon={{
                                            component: <FaEdit />,
                                            size: 15,
                                            float: 'left',
                                        }}
                                        text={i18n.get('edit')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="admin-list-container">
                        {
                            <div className="admin-list-container-box">
                                <div className="admin-list-header">
                                    <span>{i18n.get('admin:list')}</span>
                                    <div className="admin-header-right">
                                        <div className="admin-profile-added-admin">
                                            {
                                                this.state.adminData.type === 0 &&
                                                <Button
                                                    color='#ccc'
                                                    backgroundColor="#404142"
                                                    onClick={() => {
                                                        GLOBALS.promise.view.emit('onGroupProcess', {
                                                            show: true,
                                                            type: 'addNewAdmin',
                                                        });
                                                    }}
                                                    icon={{
                                                        component: <FaPlus />,
                                                        size: 15,
                                                        float: 'left',
                                                    }}
                                                    text={i18n.get('add:new:admin')} />
                                            }
                                            {/* {
                                                this.state.adminListVisibility === false ?
                                                    <Button
                                                        color='#ccc'
                                                        backgroundColor="#404142"
                                                        onClick={() => {
                                                            this.setState({
                                                                adminListVisibility: true,
                                                            });
                                                        }}
                                                        icon={{
                                                            component: <FaChevronDown />,
                                                            size: 18,
                                                            float: 'right',
                                                        }}
                                                        text={i18n.get('admin:list:show')} />
                                                    : <Button
                                                        color='#ccc'
                                                        backgroundColor="#404142"
                                                        onClick={() => {
                                                            this.setState({
                                                                adminListVisibility: false,
                                                            });
                                                        }}
                                                        icon={{
                                                            component: <FaChevronRight />,
                                                            size: 18,
                                                            float: 'right',
                                                        }}
                                                        text={i18n.get('admin:list:hide')} />
                                            } */}
                                        </div>
                                        <Dropdown
                                            className='navbar-header-dropdown'
                                            animationPosition='norteast'
                                            items={dropdownItems}
                                            onSelect={(i) => {
                                                switch (dropdownItems[i]) {
                                                    case i18n.get('enabledAdminsList'):
                                                        this.setState({
                                                            activeList: 'enabledAdminsList',
                                                        });
                                                        break;
                                                    case i18n.get('disabledAdminsList'):
                                                        this.setState({
                                                            activeList: 'disabledAdminsList',
                                                        });
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }}
                                            buttonProps={{
                                                title: i18n.get(this.state.activeList),
                                                text: i18n.get(this.state.activeList),
                                                size: 15,
                                                type: 'transparent',
                                                color: '#9f9f9f',
                                                icon: {
                                                    float: 'left',
                                                    component: <FaChevronDown />,
                                                    size: 15,
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                {
                                    this.state[this.state.activeList].length === 0 &&
                                    <div className='not-found-component-container'>
                                        <span className='not-found-component-header'>{i18n.get('not:found:admin')}</span>
                                    </div>
                                }
                                <div className="admin-list-box">
                                    <ul>
                                        {
                                            this.state[this.state.activeList].map((x, i) => {
                                                return <li
                                                    key = {i}
                                                    className="admin-list-item">
                                                    <div>
                                                        <img
                                                            alt={'Kullanıcı Resmi'}
                                                            className="admin-list-item-avatar"
                                                            src={require('../asset/image/user.png').default}/>
                                                    </div>
                                                    <div className="admin-list-item-info">
                                                        <span>
                                                            <span className="admin-list-item-info-title">{i18n.get('admin:name')}: </span>{x.name}
                                                        </span>
                                                        <span className="admin-list-item-info-mail">
                                                            <span className="admin-list-item-info-title">{i18n.get('admin:mail')}: </span>{x.mail}
                                                        </span>
                                                    </div>
                                                    {
                                                        this.state.adminData.type === 0 &&
                                                        (this.state.activeList === 'enabledAdminsList' ?
                                                            <div className="admin-list-item-remove-button">
                                                                <Button
                                                                    color='#e4e9eb'
                                                                    type='transparent'
                                                                    backgroundColor="#404142"
                                                                    onClick={() => {
                                                                        GLOBALS.promise.view.emit('onGroupProcess', {
                                                                            show: true,
                                                                            type: 'deleteAdmin',
                                                                            props: {
                                                                                userName: x.username,
                                                                            },
                                                                        });
                                                                    }}
                                                                    icon={{
                                                                        component: <FaTrash />,
                                                                        size: 15,
                                                                        float: 'left',
                                                                    }}/>
                                                            </div>
                                                            :
                                                            <Button
                                                                color='#404142'
                                                                backgroundColor='#7cbb40'
                                                                onClick = {() => this.enabledAdmin(x.username)}
                                                                text={i18n.get('enabled')}/>
                                                        )
                                                    }
                                                </li>;
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

AdminProfile.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
};
