import React, { Component } from 'react';

import { FaWindowClose } from 'react-icons/fa';

import electronlogo from '../../../asset/image/electron.png';

import '../../../asset/css/style.css';

import PropTypes from 'prop-types';

import ReleaseRequest from '../../../request/index.js';

import {toastMessage} from '../../../common/index.js';

import {
    AddProductPopup,
} from '../../AddProductPopup/AddProductPopup';

import i18n from '../../../i18n';

import GLOBALS from '../../../globals.js';


export default class AddNewProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            icon: electronlogo,
        };

        this.iconInput = React.createRef();
        this.bundleId = React.createRef();
        this.productName = React.createRef();
        this.description = React.createRef();
        this.productType = React.createRef();

        this.links = {};

        GLOBALS.productLinks.forEach(x => {
            this.links[x] = React.createRef();
        });

        this.addProduct = this.addProduct.bind(this);
        this.getImageIcon = this.getImageIcon.bind(this);
        this.changeImagePreview = this.changeImagePreview.bind(this);
        this.checkBundleId = this.checkBundleId.bind(this);
    }

    getImageIcon(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }


    changeImagePreview() {
        const input = this.iconInput.current;

        if (!input.files || !input.files[0])
            return;

        this.getImageIcon(input.files[0])
        .then(image => {
            this.setState({
                icon: image,
            });
        });
    }


    /**
     * Yeni bir ürün ekler
    */
    async addProduct() {
        const input = this.iconInput.current;

        let icon = electronlogo;
        if (input.files && input.files[0])
            icon = await this.getImageIcon(input.files[0]);

        const links = {};

        Object.keys(this.links).map(x => {
            if (this.links[x].current.value) {
                links[x] = this.links[x].current.value;
            }
        });

        const product = {
            bundle_id: this.bundleId.current.value,
            name: this.productName.current.value,
            description: this.description.current.value,
            icon: icon,
            product_type: this.productType.current.value,
            links: links,
        };

        await ReleaseRequest.addProduct(product)
        .then((res) => {
            if (res.data) {
                GLOBALS.promise.view.emit('refreshProductList');
                GLOBALS.promise.view.emit('onGroupProcess', { show: false });
                toastMessage('success', i18n.get('added:product:success'));
            }

            // TODO res.data gelmediği senaryo yazılmalı
        })
        .catch(err => {
            if (err.status === 409)
                toastMessage('error', i18n.get('another:product:same:name'));
            else
                toastMessage('error', i18n.get('could:not:add:product'));
        });
    }

    checkBundleId(event) {
        var regex = /[^\w\s-\.]/g;

        if ((regex).test(this.bundleId.current.value)) {
            toastMessage('error', i18n.get('could:not:special:characters'));
            this.bundleId.current.value = event.target.value.replace(regex, '');
        }
    }

    render() {
        var productTypes = {
            1: 'electron',
            2: 'ios_mobile',
            3: 'desktop',
        };

        return (
            <AddProductPopup
                show={true}
                bigPopup={true}
                header={i18n.get('add:new:product')}
                headerButtons={[{
                    type: 'transparent',
                    color: '#ccc',
                    title: i18n.get('close'),
                    icon: {
                        component: <FaWindowClose />,
                        size: 25,
                    },
                    onClick: this.props.onClose,
                }]}
                renderContent={() => (
                    <div>
                        <div className="add-product-form-content">
                            <div>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();

                                        this.addProduct();
                                    }}>
                                    <div className ="product-row">
                                        <div style={{width: '450px'}}>
                                            <div className="form-input">
                                                <p className="add-release--input-title">{i18n.get('product:icon')}</p>
                                                <img
                                                    id="target"
                                                    alt={i18n.get('product:icon')}
                                                    className="product-icon"
                                                    src={this.state.icon} />
                                                <div style={{width: '100%', float: 'left'}}>
                                                    <input
                                                        ref={this.iconInput}
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.changeImagePreview}/>
                                                </div>
                                            </div>
                                            <div className="form-input">
                                                <p className="add-release--input-title">{i18n.get('choose:type:product:add')}</p>
                                                <select
                                                    required
                                                    ref={this.productType}
                                                    className="input-style">
                                                    <option
                                                        value=''
                                                        default
                                                        selected
                                                        disabled>
                                                        {i18n.get('please:select:type')}
                                                    </option>
                                                    {
                                                        Object.keys(productTypes).map((x, i) => (
                                                            <option key={i} value={x}>
                                                                {productTypes[x]}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-input">
                                                <p className="add-release--input-title">{i18n.get('product:bundleId')}</p>
                                                <input
                                                    onChange={this.checkBundleId}
                                                    ref={this.bundleId}
                                                    type="text"
                                                    required
                                                    className="input-style"
                                                />
                                            </div>
                                            <div className="form-input">
                                                <p className="add-release--input-title">{i18n.get('product:name')}</p>
                                                <input
                                                    type="text"
                                                    ref={this.productName}
                                                    className="input-style"
                                                    required
                                                />
                                            </div>
                                            <div className="form-input">
                                                <p className="add-release--input-title">{i18n.get('product:description')}</p>
                                                <textarea
                                                    ref={this.description}
                                                    rows="5"
                                                    cols="47"
                                                    className="textarea-style">
                                                </textarea>
                                            </div>

                                        </div>
                                        <div style={{width: '450px', marginLeft: '10px'}}>
                                            <div className="product-link">
                                                <p className="add-release--input-title link">{i18n.get('product:links')}</p>
                                            </div>
                                            {
                                                GLOBALS.productLinks.map((x, i) => (
                                                    <div
                                                        key={i}
                                                        className="form-input">
                                                        <p
                                                            className="add-release--input-title">
                                                            {x}
                                                        </p>
                                                        <input
                                                            ref={this.links[x]}
                                                            type="text"
                                                            className="input-style"
                                                        />

                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div
                                        className="add-product-button-box">
                                        <button
                                            className="add-product-button">
                                            {i18n.get('add:new:product')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            />
        );
    }
}

AddNewProduct.propTypes = {
    onClose: PropTypes.func,
};
