const base64 = require('base-64');

/* Common Methods */
const common = {
    /**
     * oturum içeriğinden kişinin şifresini getirir
     * @param auth {string} - base64 oturum anahtarı
    */
    getAuthPassword: (auth) => {
        auth = base64.decode(auth);
        return auth.substr(auth.indexOf(':') + 1);
    },

    /**
     * oturum anahtarını veya kullanıcı adı ve şifreyi kullanarak
     * Authorization header oluşturur
     * @param client {object} - bnet.api.jd'in kendisi
     * @param password {string} - eğer password varsa basic olarak döndürür
    */
    getAuthKey: (client, password) => {
        if (client.token) {
            return `Bearer ${client.token}`;
        }

        return null;
    },

    /**
     * girilen json değerini x-www-form-urlencoded tipine çevirir
     * @param json {object} - çevrilecek json
    */
    jsonToUrlencode: (data) => {
        var formBody = [];
        for (var property in data) {
            // nested
            if (data[property] instanceof Object) {
                var encodedKey = encodeURIComponent(property);

                for (var nested in data[property]) {
                    var nested = encodeURIComponent(nested);
                    var encodedValue = encodeURIComponent(data[property][nested]);
                    formBody.push(`${encodedKey}[${nested}]=${encodedValue}`);
                }
            }
            else {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(data[property]);
                formBody.push(encodedKey + '=' + encodedValue);
            }
        }
        formBody = formBody.join('&');
        return formBody;
    },

    /*
     * Response status değerine göre callback çağırır
     * @param status {int} - response status
     * @param callbacks {BNetAPIJS|Object} - içinde callback içeren bir object
    */
    generalStatusCallback: (status, callbacks) => {
        // call unauthorized
        switch (status) {
            case 401:
                if (callbacks.onUnauthorized instanceof Function)
                    callbacks.onUnauthorized();
                break;
            case 403:
                if (callbacks.onForbidden instanceof Function)
                    callbacks.onForbidden();
                break;
            default:
                break;
        }
    },

    /*
     * Tarihi YYYYMMDD şeklinde getirir
     * @param {date} date - hesaplanması gereken tarih yoksa o anki tarih alınır
    */
    ymd: (date) => {
        var x = date || new Date();
        var y = x.getFullYear().toString();
        var m = (x.getMonth() + 1).toString();
        var d = x.getDate().toString();
        (d.length === 1) && (d = '0' + d);
        (m.length === 1) && (m = '0' + m);
        var yyyymmdd = y + m + d;
        return yyyymmdd;
    },

};

module.exports = common;
