import trTR from './tr-TR';

class I18N {

    get(key) {
        return trTR[key];
    }

}

export default new I18N();
