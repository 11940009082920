import React, { Component } from 'react';

import {
    Popup, Button,
} from '../../index';

import '../../../asset/css/style.css';

import PropTypes from 'prop-types';

import {FaWindowClose, FaKey, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

import ReleaseRequest from '../../../request/index.js';

import {toastMessage} from '../../../common/index.js';

import GLOBALS from '../../../globals.js';

import i18n from '../../../i18n';

export default class AddNewAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: props.adminData.name,
            mail: props.adminData.mail,
            username: props.adminData.username,
            newPassword: '',
            oldPassword: '',
            passwordChange: false,
            newPasswordEdit: false,
            hidden: true,
        };

        this.updateAdmin = this.updateAdmin.bind(this);
        this.oldPassword = this.oldPassword.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    async updateAdmin() {
        const {
            username,
            name,
            mail,
            newPassword,
        } = this.state;

        await ReleaseRequest.updateAdmin(username, name, newPassword, mail).then((res) => {
            if (res) {
                GLOBALS.promise.view.emit('onGroupProcess', { show: false });
                GLOBALS.promise.view.emit('refreshActiveAdmin');
                toastMessage('success', i18n.get('admin:success:updated'));
                if (this.state.newPasswordEdit) {
                    localStorage.clear();
                    GLOBALS.promise.view.emit('pageProcessing', '/admin');
                }
            }
        })
        .catch(err => {
            if (err.status === 409)
                toastMessage('error', i18n.get('another:admin:same:name'));
            else
                toastMessage('error', i18n.get('admin:update:error'));
        });
    }

    oldPassword() {
        ReleaseRequest.getOldPassword(this.state.oldPassword).then((res) => {
            if (res.data) {
                this.setState({
                    newPasswordEdit: true,
                    passwordChange: false,
                });
            }
            else {
                toastMessage('warning', i18n.get('entered:wrong:password'));
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <Popup
                show={true}
                bigPopup={true}
                header={i18n.get('update:admin')}
                title={i18n.get('close')}
                headerButtons={[{
                    type: 'transparent',
                    color: '#ccc',
                    icon: {
                        component: <FaWindowClose />,
                        size: 25,
                    },
                    onClick: this.props.onClose,
                }]}
                renderContent={() => (
                    <div >
                        <div className="add-version-form-content-row">
                            <div>
                                <div>
                                    <p className="add-version-input-title">{i18n.get('admin:name')}</p>
                                    <input type="text" className="input-style" value={this.state.name} onChange={(e) => {
                                        this.setState({
                                            name: e.target.value,
                                        });
                                    }}/>
                                </div>
                                <div>
                                    <p className="add-version-input-title">{i18n.get('admin:mail')}</p>
                                    <input
                                        type="text"
                                        className="input-style"
                                        value={this.state.mail}
                                        onChange={(e) => {
                                            this.setState({
                                                mail: e.target.value,
                                            });
                                        }}/>
                                </div>
                                {   
                                    this.state.newPasswordEdit === true ?
                                        <div>
                                            <p className="add-version-input-title">{i18n.get('new:password')}</p>
                                            <input
                                                type={this.state.hidden ? 'password' : 'text'}
                                                value={this.state.newPassword}
                                                className="input-style"
                                                onChange={(e) => {
                                                    this.setState({
                                                        newPassword: e.target.value,
                                                    });
                                                }}/>
                                            <span
                                                className="update-password-show"
                                                onClick={this.toggleShow}
                                                title={this.state.hidden ? i18n.get('show:password') : i18n.get('hide:password')}>
                                                {this.state.hidden ? <FaRegEye/> : <FaRegEyeSlash/>}
                                            </span>
                                        </div>
                                        :
                                        this.state.passwordChange === false ?
                                            <div>
                                                <Button
                                                    className="product-more-button"
                                                    color="#fff"
                                                    backgroundColor="#99a9c4"
                                                    icon={{
                                                        component: <FaKey />,
                                                        size: 19,
                                                        float: 'left',
                                                    }}
                                                    onClick={() => {
                                                        this.setState({
                                                            passwordChange: true,
                                                        });
                                                    }}
                                                    text={i18n.get('change:password')} />
                                            </div>
                                            :
                                            <div>
                                                <p className="add-version-input-title">{i18n.get('old:password')}</p>
                                                <input
                                                    type={this.state.hidden ? 'password' : 'text'}
                                                    value={this.state.oldPassword}
                                                    className="input-style"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            oldPassword: e.target.value,
                                                        });
                                                    }}/>
                                                <span
                                                    className="update-password-show"
                                                    onClick={this.toggleShow}
                                                    title={this.state.hidden ? i18n.get('show:password') : i18n.get('hide:password')}>
                                                    {this.state.hidden ? <FaRegEye/> : <FaRegEyeSlash/>}
                                                </span>
                                                <Button
                                                    className="product-more-button"
                                                    color="#fff"
                                                    backgroundColor="#99a9c4"
                                                    icon={{
                                                        component: <FaKey />,
                                                        size: 19,
                                                        float: 'left',
                                                    }}
                                                    onClick={this.oldPassword}
                                                    text="Doğrula" />
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
                footerButtons={[
                    {
                        color: '#fff',
                        backgroundColor: '#474747',
                        text: i18n.get('update'),
                        onClick: this.updateAdmin,
                        className: 'footer-button',
                    }
                ]}
            />
        );
    }
}

AddNewAdmin.propTypes = {
    adminData: PropTypes.object,
    onClose: PropTypes.func,
};