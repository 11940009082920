import React, { Component } from 'react';

import {
    Popup,
} from '../../index';

import '../../../asset/css/style.css';

import PropTypes from 'prop-types';

import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';

import ReleaseRequest from '../../../request/index.js';

import { ContextMenuTrigger } from 'react-contextmenu';

import {
    FaWindowClose,
} from 'react-icons/fa';
import GLOBALS from '../../../globals.js';

import {toastMessage} from '../../../common/index.js';

import i18n from '../../../i18n';

export default class AddAsset extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            os: 'win_32',
            assetType: 'setup',
            file: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.changeDropdownItems = this.changeDropdownItems.bind(this);
        this.assetChange = this.assetChange.bind(this);
    }

    async assetAdd() {
        const {
            file,
            assetType,
            os,
        } = this.state;

        var progress = (event) => {
            if (event.lengthComputable) {
                var percent = event.loaded / event.total;
                this.setState({
                    progress: percent * 100,
                });
            }
        };

        if (!file)
            return toastMessage('warning', i18n.get('please:attach:files'));

        if (!assetType)
            return toastMessage('warning', i18n.get('please:attach:files'));


        await ReleaseRequest.addAsset(GLOBALS.product.bundleId, this.props.releaseId, file, assetType, os, progress).then((res) => {
            if (res) {
                setTimeout(() => {
                    GLOBALS.promise.view.emit('onGroupProcess', { show: false });
                    toastMessage('success', i18n.get('added:asset:success'));
                }, 1500);
            }
        })
        .catch(err => {
            toastMessage('error', i18n.get('could:not:add:asset'));
        });
    }

    handleChange(selectorFiles = FileList) {
        this.setState({
            file: selectorFiles[0],
        });
    }

    assetChange = (e) => {
        this.setState({
            assetType: e.target.value
        });
    }

    changeDropdownItems = (e) => {
        this.setState({
            os: e.target.value
        });
    }

    render() {
        var osDropdownItems = ['win_32', 'osx_64'];
        var assetDropdownItems = ['setup', 'update'];

        return (
            <Popup
                show={true}
                bigPopup={true}
                header={i18n.get('add:new:asset')}
                headerButtons={[{
                    type: 'transparent',
                    color: '#ccc',
                    icon: {
                        component: <FaWindowClose />,
                        size: 25,
                    },
                    onClick: this.props.onClose,
                }]}
                renderContent={() => (
                    <div >
                        <div className="add-asset-form-content">
                            <div className="more-form-content-container">
                                <div className="asset-form-input">
                                    <p className="add-asset-input-title">{i18n.get('asset:type')}</p>
                                    {/* <Dropdown
                                        className='navbar-header-dropdown'
                                        animationPosition='nortwest'
                                        items={assetDropdownItems}
                                        onSelect={(i) => {
                                            this.setState({
                                                assetType: assetDropdownItems[i],
                                            });
                                        }
                                        }
                                        buttonProps={{
                                            className: 'header-text',
                                            text: this.state.assetType,
                                            color: '#9f9f9f',
                                            backgroundColor: '#404142',
                                            icon: {
                                                float: 'right',
                                                component: <FaWindowClose />,
                                                size: 22,
                                            },
                                        }}
                                    /> */}

                                    <ContextMenuTrigger id="chat-context-menu">
                                        <select
                                            className="select-menu"
                                            onChange={this.assetChange}>
                                            <option value="none" selected="true" disabled="hidden">
                                            Kanal Seçiniz
                                            </option>
                                            {
                                                assetDropdownItems.map((x, i) => (
                                                    <option
                                                        key={x}
                                                        value={x}>
                                                        {x}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </ContextMenuTrigger>
                                </div>
                                <div className="asset-form-input">
                                    <p className="add-asset-input-title">{i18n.get('platform')}</p>
                                    {/* <Dropdown
                                        className='navbar-header-dropdown'
                                        animationPosition='nortwest'
                                        items={osDropdownItems}
                                        onSelect={(i) => {
                                            this.setState({
                                                os: osDropdownItems[i],
                                            });
                                        }}
                                        buttonProps={{
                                            className: 'header-text',
                                            text: this.state.os,
                                            color: '#9f9f9f',
                                            backgroundColor: '#404142',
                                            icon: {
                                                float: 'right',
                                                component: <FaWindowClose />,
                                                size: 22,
                                            },
                                        }}
                                    /> */}
                                    <ContextMenuTrigger id="chat-context-menu">
                                        <div>
                                            <select
                                                className="select-menu"
                                                onChange={this.changeDropdownItems}>
                                                <option value="none" selected="true" disabled="hidden">
                                                Platform Seçiniz
                                                </option>
                                                {
                                                    osDropdownItems.map((x, i) => (
                                                        <option
                                                            key={x}
                                                            value={x}>
                                                            {x}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </ContextMenuTrigger>
                                </div>
                                <div className="asset-form-input">
                                    <p className="add-asset-input-title">{i18n.get('choose:file')}</p>

                                    <label className="input-style-file"> {this.state.file ? this.state.file.name : i18n.get('click:select:file')}
                                        <input type="file" onChange={ (e) => this.handleChange(e.target.files) } />
                                    </label>

                                </div>
                                <div className="asset-form-input">
                                    <Progress percent={this.state.progress} status="success" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                footerButtons={[
                    {
                        color: '#fff',
                        backgroundColor: '#474747',
                        text:i18n.get('upload'),
                        className: 'footer-button',
                        onClick:() => {this.assetAdd();},
                    }
                ]}
            />
        );
    }
}

AddAsset.propTypes = {
    releaseId: PropTypes.number,
    onClose: PropTypes.func,
};
