import React, { Component } from 'react';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="footer">
                <div className="footer-left">
                    <h4>bnet.support@detaysoft.com</h4>
                </div>
                <div className="footer-right">
                    <div
                        className="header-right-img"
                        style={{
                            backgroundImage:`url('${require('../../asset/image/facebook.svg').default}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',

                        }}>
                    </div>
                    <div
                        className="header-right-img"
                        style={{
                            backgroundImage:`url('${require('../../asset/image/twitter.svg').default}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}>
                    </div>
                    <div
                        className="header-right-img"
                        style={{
                            backgroundImage:`url('${require('../../asset/image/linkedin.svg').default}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}>
                    </div>
                </div>
            </div>
        );
    }
}
