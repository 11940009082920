module.exports = (client: Object) => {

    var defaultHeader = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    client.getOS = (osId) => {
        var url = `${client.urls.SERVICE}os/${osId}`;

        return client.get(url, defaultHeader)
        .then(res => res.json());
    };
};

