module.exports = (client: Object) => {
    /*
     * Product listesini getirir.
     * @param userJid {string} - Mesajları getirilecek kullanıcının jid'si ör: 00001385@bnet.detaysoft.com
     * @param keyword {string} - aranacak keyword
    */

    /*
     * openfire'ın tüm isteklerini json kabul etmek için bu kullanılır
    */
    var defaultHeader = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    client.getReleaseList = (bundleId, page) => {
        var pages = parseInt(page) || 1;
        var url = `${client.urls.SERVICE}${bundleId}/release?page=${pages}&sort=created_date&channel=all`;

        return client.get(url, defaultHeader)
        .then(res => res.json());
    };

    client.addRelease = (bundleId, version, notes, channel, title) => {
        var url = `${client.urls.SERVICE}${bundleId}/release`;

        return client.post(url, {
            version: version,
            notes: notes,
            channel: channel,
            title: title,
        }, defaultHeader)
        .then(res => res.json());
    };

    client.updateRelease = (bundleId, releaseId, version, notes, channel, title) => {
        var url = `${client.urls.SERVICE}${bundleId}/release/${releaseId}`;
        var body = {
            version: version,
            notes: notes,
            channel: channel,
            title: title,
        };
        return client.put(url, body, defaultHeader);
    };

    client.deleteRelease = (bundleId, releaseId) => {
        var url = `${client.urls.SERVICE}${bundleId}/release/${releaseId}`;

        return client.delete(url, null, defaultHeader);
    };
};
