const common = require('./common/index.js');
const GLOBALS = require ('../globals.js');
const XMLHttpRequest = window.XMLHttpRequest || global.XMLHttpRequest;

class ReleaseServerRequest {
    token: String;
    config: Object;
    urls: Object;
    services: Object;
    tokenEnd: Number;

    // Offical callbacks
    onUnauthorized: Function;
    onForbidden: Function;

    constructor() {
        this.config = {
            KEYCLOAK_VALIDATE_KEY: '',
            BUNDLE_ID: '',
        };

        // TODO anadizin bağımlılığı kaldırılınca burası değiştirilmeli.
        this.urls = {
            SERVICE: window.location.origin + '/api/',
        };
    }

    init(config, urls, services) {
        var key;
        if (config instanceof Object) {
            for (key in config) {
                this.config[key] = config[key];
            }
        }

        if (urls instanceof Object) {
            for (key in urls) {
                this.urls[key] = urls[key];
            }
        }

        if (services instanceof Object) {
            for (key in services) {
                this.services[key] = services[key];
            }
        }
    }

    use(service) {
        if (service instanceof Function) {
            service(this);
        }
    }

    request(opts, attrs, progress) {
        var self = this;

        return new Request(opts, attrs, this, progress)
        .catch(err => {
            if (err && err.status && err.status === 401) {
                localStorage.clear();
                GLOBALS.default.promise.view.emit('onGroupProcess', { show: false });
                GLOBALS.default.promise.view.emit('pageProcessing', '/admin');

                if (self.onUnauthorized instanceof Function) {
                    self.onUnauthorized(err);
                }
            }

            throw err;
        });
    }

    post(url, data, headers, formData = false) {
        this.token = localStorage.getItem('session_ticket');

        headers = Object.assign({}, {
            'Authorization': common.getAuthKey(this),
        }, headers);

        return this.request({
            uri: url,
            body: formData ? data : common.jsonToUrlencode(data),
            method: 'POST',
            strictSSL: true,
            headers: headers,
            ignoreAuth: false,
        });
    }

    get(url, headers, unauthorized = false) {
        this.token = localStorage.getItem('session_ticket');
        headers = Object.assign({}, {
            'Authorization': common.getAuthKey(this),
        }, headers);

        return this.request({
            uri: url,
            body: null,
            method: 'GET',
            strictSSL: true,
            headers: headers,
            ignoreAuth: unauthorized,
        });
    }

    put(url, data, headers) {
        this.token = localStorage.getItem('session_ticket');

        headers = Object.assign({}, {
            'Authorization': common.getAuthKey(this),
        }, headers);

        return this.request({
            uri: url,
            body: common.jsonToUrlencode(data),
            method: 'PUT',
            strictSSL: true,
            headers: headers,
        });
    }

    delete(url, data, headers) {
        var body = !data ? null : JSON.stringify(data);
        this.token = localStorage.getItem('session_ticket');

        headers = Object.assign({}, {
            'Authorization': common.getAuthKey(this),
        }, headers);

        return this.request({
            uri: url,
            body: body,
            method: 'DELETE',
            strictSSL: true,
            headers: headers,
        });
    }
}

class Request {
    constructor(opts, attrs, callbacks, progress) {
        return new Promise((resolve, reject) => {
            if (!opts.ignoreAuth && !opts.headers['Authorization']) {
                var err = new Error('User not authorized!');

                return reject({
                    err: err,
                    status: 401,
                });
            }

            var xhr = new XMLHttpRequest();

            xhr.onerror = (err) => {
                xhr.onreadystatechange = null;
                reject(err || '');
            };

            if (xhr.upload && xhr.upload.addEventListener instanceof Function) {
                xhr.upload.addEventListener('progress', progress);
            }

            if (xhr.addEventListener instanceof Function) {
                xhr.addEventListener('progress', progress);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    // status callbacks
                    common.generalStatusCallback(xhr.status, callbacks);

                    if (xhr.status < 200 || xhr.status >= 400) {
                        xhr.onerror = xhr.ontimeout = xhr.onabort = null;
                        return reject(xhr);
                    }

                    var res = xhr.response;
                    if (!xhr.responseType) {
                        res = xhr.responseText;
                    }

                    try {
                        res = res.json();
                    } catch (e) {
                        // empty
                    }

                    resolve(new Response(xhr, res));
                }
            };

            xhr.open(opts.method, opts.uri);

            if (opts.headers) {
                for (var i in opts.headers) {
                    xhr.setRequestHeader(i, opts.headers[i]);
                }
            }

            if (attrs instanceof Object) {
                for (var key in attrs) {
                    xhr[key] = attrs[key];
                }
            }

            xhr.send(opts.body);
        });
    }
}

class Response {
    xhr: Object;
    response: String;
    status: Number;
    headers: Object;

    constructor(xhr, res) {
        this.xhr = xhr;
        this.response = res;
        this.status = xhr.status;
        this.headers = {};

        xhr.getAllResponseHeaders().split('\n').map(x => {
            if (x) {
                var value = x.substring(x.indexOf(':') + 1, x.length);
                this.headers[(x.split(':')[0]).toLowerCase()] = value && value.trim();
            }
        });
    }

    json() {
        this.response = JSON.parse(String(this.response));
        if (typeof this.response === 'object') {
            return this.response;
        }

        throw new Error('json:parse:failed');
    }

    text() {
        return this.response;
    }

    xml() {
        var Parser = window.DOMParser || global.DOMParser;
        var parseStr = this.response || '';
        var str = new Parser().parseFromString(parseStr.trim(), 'text/xml');
        return str;
    }
}

const api = new ReleaseServerRequest();
api.use(require('./plugins'));

export default api;
