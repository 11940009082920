import React, { Component } from 'react';
import GLOBALS from '../../globals.js';

import {
    AddNewAdmin,
    DeleteAdmin,
    UpdateAdmin,

    AddNewAsset,
    DeleteAsset,
    UpdateAsset,

    AddNewProduct,
    DeleteProduct,
    UpdateProduct,

    AddNewRelease,
    DeleteRelease,
    UpdateRelease,

    MoreInfo,
} from './index';

export default class PopupModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 'addNewRelease',
            show: false,
            props: {},
        };
    }

    componentDidMount() {
        var self = this;
        GLOBALS.promise.view.on('onGroupProcess', args => {
            GLOBALS.active.popup = args.show || false;
            var props = args.props ?
                Object.assign((args.props),{'history': self.props.history})
                :
                Object.assign({},{'history': self.props.history});

            self.setState({
                show: args.show || false,
                type: args.type || 'new',
                props: props,
            });
        });
    }

    close() {
        this.setState({ show: false });
        GLOBALS.active.popup = false;
    }

    render() {
        if (this.state.show)
            return (
                <div
                    onDrop={e => e.preventDefault()}
                    onDragOver={e => e.preventDefault()}>
                    {
                        this.state.type === 'addNewAdmin' &&
                        <AddNewAdmin
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'addAsset' &&
                        <AddNewAsset
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'addNewRelease' &&
                        <AddNewRelease
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'addNewProduct' &&
                        <AddNewProduct
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'deleteAdmin' &&
                        <DeleteAdmin
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'deleteRelease' &&
                        <DeleteRelease
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'deleteAsset' &&
                        <DeleteAsset
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'deleteProduct' &&
                        <DeleteProduct
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'updateProduct' &&
                        <UpdateProduct
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'updateRelease' &&
                        <UpdateRelease
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'updateAdmin' &&
                        <UpdateAdmin
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'updateAsset' &&
                        <UpdateAsset
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                    {
                        this.state.type === 'moreInfo' &&
                        <MoreInfo
                            onClose={this.close.bind(this)}
                            {...this.state.props} />
                    }
                </div>
            );
        return null;
    }
}
