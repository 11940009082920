import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GLOBALS from '../globals.js';

import {
    Button,
    Dropdown,
} from '../components';

import {
    FaTrash,
    FaEdit,
    FaInfo,
    FaChevronDown,
    FaPlus,
} from 'react-icons/fa';

import ReleaseRequest from '../request';

import i18n from '../i18n';

class Product extends Component{
    constructor(props) {
        super(props);
        this.state = {
            userAuth: false,
            disabledProductList: [],
            enabledProductList: [],
            activeList: 'enabledProductList',
        };

        this.productList = this.productList.bind(this);
    }

    componentDidMount() {
        var user = localStorage.getItem('user');
        if (user) {
            this.setState({
                userAuth: user,
            });
        }
        this.productList();
        GLOBALS.promise.view.on('refreshProductList', this.productList);
    }

    componentWillUnmount() {
        GLOBALS.promise.view.off('refreshProductList', this.productList);
    }

    /**
     * Ürünleri Getirir.
    */
    async productList() {
        var disabledProductList = [];
        var enabledProductList = [];

        await ReleaseRequest.getProductList().then((res) => {
            if (res) {
                this.setState({
                    disabledProductList: disabledProductList,
                    enabledProductList: enabledProductList,
                });
            }

            res.data.map(x => {
                if (x.disabled === 1) {
                    disabledProductList.push(x);
                }
                else {
                    if (x.download_check && x.download_check.length > 0){
                        x.download_check.map(data => {
                            if (data.os_id === 1)
                                x.winLink = true;
                            else if (data.os_id)
                                x.osxLink = true;
                        });
                    }
                    if (this.state.userAuth === false) {
                        if (x.last_version !== i18n.get('no:version') || Object.keys(x.links).length > 0)
                            enabledProductList.push(x);
                    }
                    else {
                        enabledProductList.push(x);
                    }
                }

                this.setState({
                    disabledProductList: disabledProductList,
                    enabledProductList: enabledProductList,
                });
            });
        })
        .catch(err => {
            console.log(err);
        });
    }

    render() {
        var dropdownItems = [i18n.get('enabledProductList'), i18n.get('disabledProductList')];

        return (
            <div className="product">
                <div className="product-container">
                    <div className="product-buttons">
                        {
                            (this.state.activeList === 'enabledProductList' && this.state.userAuth !== false) &&
                            <div className="add-new-product">
                                <Button
                                    className="add-new-product-icon"
                                    color="#fff"
                                    type='transparent'
                                    backgroundColor="#99a9c4"
                                    icon={{
                                        component: <FaPlus />,
                                        size: 70,
                                        float: 'top',
                                    }}
                                    onClick={() => {
                                        GLOBALS.promise.view.emit('onGroupProcess', {
                                            show: true,
                                            type: 'addNewProduct',
                                        });
                                    }}
                                    text={i18n.get('add:new:product')}
                                    title={i18n.get('add:new:product')} />
                            </div>
                        }

                        {
                            this.state.userAuth !== false &&
                            <Dropdown
                                className='navbar-header-dropdown'
                                animationPosition='nortwest'
                                items={dropdownItems}
                                onSelect={(i) => {
                                    switch (dropdownItems[i]) {
                                        case i18n.get('enabledProductList'):
                                            this.setState({
                                                activeList: 'enabledProductList',
                                            });
                                            break;
                                        case i18n.get('disabledProductList'):
                                            this.setState({
                                                activeList: 'disabledProductList',
                                            });
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                                buttonProps={{
                                    className: 'header-text',
                                    title: i18n.get(this.state.activeList),
                                    text: i18n.get(this.state.activeList),
                                    type: 'transparent',
                                    color: '#9f9f9f',
                                    icon: {
                                        float: 'left',
                                        component: <FaChevronDown />,
                                        size: 22,
                                    },
                                }}
                            />
                        }
                    </div>
                    <div
                        className="product-grid">
                        {
                            this.state[this.state.activeList].map((x, i) => {
                                var linkItems = [
                                    {
                                        key: 'ios_iphone',
                                        icon: require('../asset/image/apple-logo.svg').default,
                                        name: i18n.get('ios:iphone'),
                                    },
                                    {
                                        key: 'ios_ipad',
                                        icon: require('../asset/image/apple-logo.svg').default,
                                        name: i18n.get('ios:ipad'),
                                    },
                                    {
                                        key: 'android',
                                        icon: require('../asset/image/android-os.svg').default,
                                        name: i18n.get('android'),
                                    },
                                    {
                                        key: 'windows_desktop',
                                        icon: require('../asset/image/windows-icon.svg').default,
                                        name: i18n.get('windows:desktop'),
                                    },
                                    {
                                        key: 'macos_desktop',
                                        icon: require('../asset/image/apple-logo.svg').default,
                                        name: i18n.get('macos:desktop'),
                                    },
                                    {
                                        key: 'linux_desktop',
                                        icon: require('../asset/image/linux-icon.png').default,
                                        name: i18n.get('linux:desktop'),
                                    },
                                    {
                                        key: 'web_address',
                                        icon: require('../asset/image/web_address.png').default,
                                        name: i18n.get('web:address'),
                                    }
                                ];
                                return (
                                    <div
                                        key={i}
                                        className="product-container-content">
                                        <div className="product-box-left">
                                            <img
                                                className="product-icon"
                                                src={x.icon}/>
                                            <div>
                                                {
                                                    x.special ?
                                                        <div className="product-name">
                                                            <img
                                                                src={require('../asset/image/red-circle.png').default}/>
                                                            <h3>{x.name}</h3>
                                                        </div>
                                                        :
                                                        <div className="product-name">
                                                            <img
                                                                src={require('../asset/image/green-circle.png').default}/>
                                                            <h3>{x.name}</h3>
                                                        </div>
                                                }
                                            </div>
                                            <p>{x.last_version}</p>
                                        </div>
                                        <div className="product-box-right">
                                            <div className="product-info-bottom">
                                                <p>{x.description}</p>
                                            </div>
                                            <div className="product-info-top">
                                                {
                                                    this.state.userAuth === false ?
                                                        <div className="product-download-btn">
                                                            {
                                                                x.winLink &&
                                                            <div className="button-icon">
                                                                <div className="button-icon-left">
                                                                    <div className="download-icon-circle">
                                                                        <img src={require('../asset/image/windows-icon.svg').default} className="download-icon"></img>
                                                                    </div>
                                                                    <Button
                                                                        className={x.winLink === true ? 'product-more-button' : ' product-more-button disabled'}
                                                                        color="#fff"
                                                                        backgroundColor="#99a9c4"
                                                                        text="Windows Setup"
                                                                        target={true}
                                                                        linkPointer={!x.winLink}
                                                                        link={x.winLink ? `/api/download/${x.bundle_id}/${x.last_version}/${x.channel}/win_32` : null}
                                                                    />
                                                                </div>
                                                                <div className="info-icon-circle">
                                                                    {/* <img src={require('../asset/image/info.svg').default} className="info-icon"></img> */}
                                                                    <Button
                                                                        className="info-icon"
                                                                        text={'i'}
                                                                        backgroundColor="rgb(51, 56, 57)"
                                                                        link={'/info'}/>
                                                                </div>
                                                            </div>
                                                            }
                                                            <div className="product-download-btn">
                                                                {
                                                                    x.osxLink &&
                                                                <div className="button-icon">
                                                                    <div className="button-icon-left">
                                                                        <div className="download-icon-circle">
                                                                            <img src={require('../asset/image/apple-logo.svg').default} className="download-icon"></img>
                                                                        </div>
                                                                        <Button
                                                                            className={x.osxLink === true ? 'product-more-button' : ' product-more-button disabled'}
                                                                            color="#fff"
                                                                            backgroundColor="#99a9c4"
                                                                            text="macOS Setup"
                                                                            target={true}
                                                                            linkPointer={!x.osxLink}
                                                                            link= {x.osxLink ? `/api/download/${x.bundle_id}/${x.last_version}/${x.channel}/osx_64` : null}
                                                                        />
                                                                    </div>
                                                                    <div className="info-icon-circle">
                                                                        <Button
                                                                            className="info-icon"
                                                                            text={'i'}
                                                                            backgroundColor="rgb(51, 56, 57)"
                                                                            link={'/info'}/>
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div>
                                                            <div className="links">
                                                                {
                                                                    <div className="product-download-links">
                                                                        <div className="download-links">
                                                                            {
                                                                                Object.keys(x.links).length > 0 &&
                                                                            <div className="product-download-links">
                                                                                Uygulama linkleri
                                                                                {
                                                                                    Object.keys(x.links).map((y, i) => (
                                                                                        <div
                                                                                            key={i}
                                                                                            className="links-content">
                                                                                            <div className="link-icon-circle">
                                                                                                {
                                                                                                    linkItems.map(items => {
                                                                                                        if (items.key === y)
                                                                                                            return <img src={items.icon} className="link-icon"></img>;
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                            <div className="links-name">
                                                                                                {
                                                                                                    linkItems.map(items => {
                                                                                                        if (items.key === y)
                                                                                                            return <a key={i} href={x.links[y]}>{items.name}</a>;
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                            <div className="info-icon-circle">
                                                                                                <Button
                                                                                                    className="info-icon"
                                                                                                    text={'i'}
                                                                                                    backgroundColor="rgb(51, 56, 57)"
                                                                                                    link={'/info'}/>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={'product-download-btn'}>
                                                            <Button
                                                                className="product-more-button"
                                                                color="#fff"
                                                                backgroundColor="#99a9c4"
                                                                icon={{
                                                                    component: <FaInfo />,
                                                                    size: 19,
                                                                    float: 'left',
                                                                }}
                                                                onClick={() => {
                                                                    GLOBALS.product = {
                                                                        bundleId: x.bundle_id,
                                                                        bundleName: x.name,
                                                                        icon: x.icon,
                                                                    };
                                                                    localStorage.setItem('active-product', JSON.stringify(GLOBALS.product));
                                                                }}
                                                                link={`/product/${x.bundle_id}`}
                                                                title={i18n.get('show:more')} />
                                                            <Button
                                                                className="product-more-button"
                                                                color="#fff"
                                                                icon={{
                                                                    component: <FaEdit />,
                                                                    size: 19,
                                                                    float: 'left',
                                                                }}
                                                                backgroundColor="#99a9c4"
                                                                onClick={() => {
                                                                    GLOBALS.promise.view.emit('onGroupProcess', {
                                                                        show: true,
                                                                        type: 'updateProduct',
                                                                        props: {
                                                                            bundleId: x.bundle_id,
                                                                            description: x.description,
                                                                            disabled: x.disabled,
                                                                            icon: x.icon,
                                                                            links: x.links,
                                                                        },
                                                                    });
                                                                }}
                                                                title={i18n.get('edit')} />
                                                            {
                                                                this.state.activeList === 'enabledProductList' &&
                                                    <Button
                                                        className="product-more-button"
                                                        color="#fff"
                                                        icon={{
                                                            component: <FaTrash />,
                                                            size: 19,
                                                            float: 'left',
                                                        }}
                                                        backgroundColor="#99a9c4"
                                                        onClick={() =>
                                                            GLOBALS.promise.view.emit('onGroupProcess', {
                                                                show: true,
                                                                type: 'deleteProduct',
                                                                props: {
                                                                    bundleId: x.bundle_id,
                                                                    productName: x.name,
                                                                },
                                                            })
                                                        }
                                                        title={i18n.get('delete')} />
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default Product;

Product.propType = {
    history: PropTypes.object,
};

