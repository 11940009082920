import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function toastMessage(type, message) {
    switch (type) {
        case 'success':
            toast.success(message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            break;
        case 'error':
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            break;
        case 'warning':
            toast.warn(message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            break;
        case 'info':
            toast.info(message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            break;
        default:
            break;
    }
}

export function isMobile() {
    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))
        return true;
    if (window.innerWidth <= 900)
        return true;
    return false;
}
