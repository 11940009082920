import React, { Component } from 'react';

import {
    Popup,
    Dropdown,
} from '../../index';

import '../../../asset/css/style.css';

import PropTypes from 'prop-types';

import {
    FaWindowClose,
    FaChevronDown,
} from 'react-icons/fa';

import ReleaseRequest from '../../../request/index.js';

import GLOBALS from '../../../globals.js';

import {toastMessage} from '../../../common/index.js';

import i18n from '../../../i18n';

export default class UpdateRelease extends Component {
    constructor(props) {
        super(props);
        this.state = {
            releaseId: props.res.id,
            version: props.res.version,
            notes: props.res.notes,
            title: props.res.title,
            channel: props.res.channel,
        };

        this.updateRelease = this.updateRelease.bind(this);
    }

    async updateRelease() {
        const {
            version,
            notes,
            title,
            channel,
            releaseId,
        } = this.state;

        if (!version || !notes || !title || !channel ) {
            toastMessage('warning', i18n.get('fill:blank:fields'));
        }
        else {
            await ReleaseRequest.updateRelease(GLOBALS.product.bundleId, releaseId, version, notes, channel, title).then((res) => {
                if (res) {
                    GLOBALS.promise.view.emit('refreshReleaseList');
                    GLOBALS.promise.view.emit('onGroupProcess', { show: false });
                    toastMessage('success', i18n.get('release:success:update'));
                }
            })
            .catch(err => {
                if (err.status === 409)
                    toastMessage('error', i18n.get('another:release:same:name'));
                else if (err.status === 403)
                    toastMessage('error', i18n.get('channel:must:stable'));
                else
                    toastMessage('error', i18n.get('release:updating:error'));
            });
        }
    }

    render() {
        var dropdownItems = ['stable', 'beta', 'alpha'];
        return (
            <Popup
                show={true}
                bigPopup={true}
                header={i18n.get('update:release')}
                headerButtons={[{
                    type: 'transparent',
                    color: '#ccc',
                    title: i18n.get('close'),
                    icon: {
                        component: <FaWindowClose />,
                        size: 25,
                    },
                    onClick: this.props.onClose,
                }]}
                renderContent={() => (
                    <div >
                        <div className="update-release-form-content-row">
                            <div>
                                <div className="form-input">
                                    <p className="update--input-title">{i18n.get('release:title')}</p>
                                    <input
                                        type="text"
                                        className="input-style"
                                        onChange={(e) => {
                                            this.setState({
                                                title: e.target.value,
                                            });
                                        }}
                                        value={this.state.title}/>
                                </div>
                                <div className="form-input">
                                    <p className="update-release--input-title">{i18n.get('release:name')}</p>
                                    <input
                                        type="text"
                                        className="input-style"
                                        onChange={(e) => {
                                            this.setState({
                                                version: e.target.value,
                                            });
                                        }}
                                        value={this.state.version}/>
                                </div>
                                <div className="form-input">
                                    <p className="update-release--input-title">{i18n.get('change:notes')}</p>
                                    <input
                                        type="text"
                                        className="input-style"
                                        onChange={(e) => {
                                            this.setState({
                                                notes: e.target.value,
                                            });
                                        }}
                                        value={this.state.notes}/>
                                </div>
                                <div className="form-input">
                                    <p className="update-release--input-title">{i18n.get('channel')}</p>
                                    <Dropdown
                                        className='navbar-header-dropdown'
                                        animationPosition='nortwest'
                                        items={dropdownItems}
                                        onSelect={(i) => {
                                            this.setState({
                                                channel: dropdownItems[i],
                                            });
                                        }
                                        }
                                        buttonProps={{
                                            className: 'header-text',
                                            text: this.state.channel,
                                            color: '#9f9f9f',
                                            backgroundColor: '#404142',
                                            icon: {
                                                float: 'right',
                                                component: <FaChevronDown />,
                                                size: 22,
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                footerButtons={[
                    {
                        color: '#fff',
                        backgroundColor: '#474747',
                        text: i18n.get('upload'),
                        className: 'footer-button',
                        onClick: this.updateRelease,
                    }
                ]}
            />
        );
    }
}

UpdateRelease.propTypes = {
    res: PropTypes.object,
    onClose: PropTypes.func,
};
