import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    FaHome,
    FaKey,
} from 'react-icons/fa';

import {
    FiMenu,
} from 'react-icons/fi';

import {
    Button,
    Navbar,
    Dropdown,
} from '../components/index.js';

import i18n from '../i18n';
import GLOBALS from '../globals.js';

export default class HeaderMenu extends Component{
    render() {
        var user = localStorage.getItem('user');
        var dropdownItems = [i18n.get('profile'), i18n.get('sign:out')];

        return (
            <Navbar
                className='start-container'

                // left={
                //     <div>
                //         <p className="navbar-left-items"> {i18n.get('electron:upbox')} </p>
                //     </div>
                // }
                right={
                    <div>
                        <Button
                            className="navbar-right-items"
                            type="transparent"
                            color='#ccc'
                            icon={{
                                component: <FaHome />,
                                size: 19,
                                float: 'left',
                            }}
                            link="/"
                            text={i18n.get('home')}/>
                        {
                            !user ?
                                <Button
                                    className="navbar-right-items"
                                    type="transparent"
                                    color='#ccc'
                                    icon={{
                                        component: <FaKey />,
                                        size: 19,
                                        float: 'left',
                                    }}
                                    link={'/admin'}
                                    text={i18n.get('admin')} />
                                :
                                <div className="navbar-user-control-items">
                                    {/* <Button
                                        className="navbar-right-items"
                                        type="transparent"
                                        color='#ccc'
                                        icon={{
                                            component: <FiPackage />,
                                            size: 19,
                                            float: 'left',
                                        }}
                                        link="/product"
                                        text={i18n.get('products')} /> */}

                                    <Dropdown
                                        className='navbar-header-dropdown'
                                        animationPosition='norteast'
                                        items={dropdownItems}
                                        onSelect={(i) => {
                                            switch (dropdownItems[i]) {
                                                case i18n.get('profile'):
                                                    this.props.history.replace('/adminProfile');
                                                    break;
                                                case i18n.get('sign:out'):
                                                    localStorage.clear();
                                                    this.props.history.replace('/');
                                                    GLOBALS.promise.view.emit('refreshProductList');
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }}
                                        buttonProps={{
                                            className: 'menu-button',
                                            text: i18n.get('admin'),
                                            type: 'transparent',
                                            color: '#9f9f9f',
                                            icon: {
                                                float: 'left',
                                                component: <FiMenu />,
                                                size: 22,
                                            },
                                        }}
                                    />
                                </div>
                        }
                    </div>
                }
            />
        );
    }
}

HeaderMenu.propTypes = {
    history: PropTypes.object,
};
